import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Title from '../components/Title'


function Privacy () {
    return (
      <>
        <Nav />
        <Title title='Politique de confidentialité' />
        <div className='flex flex-col mb-20 mr-20'>
          <div className='mt-10 ml-14 max-w-4xl'>
              <p>
                1. Collecte de données : Nous ne collectons aucune donnée personnelle de nos utilisateurs, que ce soit directement via l'application ou avec des tiers.
              </p>
          </div>
          <div className='mt-10 ml-14 max-w-4xl'>
              <p>
                2. Utilisation de données : Nous n'utilisons pas les données personnelles de nos utilisateurs à des fins commerciales ou publicitaires.
              </p>
          </div>
          <div className='mt-10 ml-14 max-w-4xl'>
              <p>
                3. Partage de données : Nous ne partageons aucune donnée personnelle de nos utilisateurs avec des tiers.
              </p>
          </div>
          <div className='mt-10 ml-14 max-w-4xl'>
              <p>
                4. Partage de données : Nous ne partageons aucune donnée personnelle de nos utilisateurs avec des tiers.
              </p>
          </div>
          <div className='mt-10 ml-14 max-w-4xl'>
              <p>
                5. Consentement : En téléchargeant et en utilisant notre application, vous consentez à ces règles de confidentialité.
              </p>
          </div>
          <div className='mt-10 ml-14 max-w-4xl'>
              <p>
                6. Consentement : En téléchargeant et en utilisant notre application, vous consentez à ces règles de confidentialité.
              </p>
          </div>
          <div className='mt-10 ml-14 max-w-4xl'>
              <p>
                7. Contact : Si vous avez des questions ou des préoccupations concernant nos règles de confidentialité, veuillez nous contacter à l'adresse suivante : tim.chevalier@bonson-web-solutions.com.
              </p>
          </div>
        </div>
        <Footer />
      </>
    )
  }
  
  export default Privacy