import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Search from '../components/Search'

function Home () {
  return (
    <>
      <Nav />
      <Search />
      <Footer fixed={"md:fixed md:bottom-0"} />
    </>
  )
}

export default Home
