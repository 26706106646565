import React from 'react'

function Footer ({fixed}) {
  return (
    <footer className={`text-center border-t-4
    md:inset-x-0
    p-4 z-[-50] md:z-0 mt-36 md:mt-4 ${fixed}`}>
        <a className='flex items-center justify-center mb-2' target='_blank' href='https://play.google.com/store/apps/details?id=com.bonsonwebsolutions.nouchitionnaire&hl=fr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width="120" alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'/></a>
        Copyright © {new Date().getFullYear()}  Nouchitionnaire - <a rel='noreferrer' target="_blank" href="https://bonson-web-solutions.com">Bonson Web Solutions</a>.
        
    </footer>
  )
}

export default Footer
