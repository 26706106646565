import React from 'react'

function Title ({ title, subtitle }) {
  return (
        <>
            <div className='flex flex-col justify-center items-center'>
                <div className='mt-10'>
                    <div>
                        <h1 className="text-4xl text-center">{title}</h1>
                    </div>
                    <div className='mt-3 pl-10 pr-10 text-center'>
                        <p>{subtitle}</p>
                    </div>
                </div>
            </div>
        </>
  )
}

export default Title
