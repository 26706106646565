import axios from 'axios'
import React, { useState } from 'react'
import Title from './Title'
import Flags from './Flags'
import Form from './Form'
import Result from './Result'
import TypeMe from 'react-typeme'

function Search () {
  const API_URL = process.env.REACT_APP_API_URL
  const API_KEY = process.env.REACT_APP_API_KEY

  const [lang, setLang] = useState('fr')
  const [icon, setIcon] = useState('arrow-left')
  const [searchDetail, setSearchDetail] = useState({})
  const [items, setItems] = useState([])
  const [text, setText] = useState()

  const handleOnSearch = (string) => {
    if (string !== '') {
      setText(string)
      axios.get(`${API_URL}/api/search/${lang}/${string}`, {
        headers: {
          'Content-type': 'Application/json',
          Authorization: `Bearer ${API_KEY}`
        }
      })
        .then((res) => {
          setItems(res.data.words)
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setSearchDetail({})
    }
  }

  const handleOnSelect = (item) => {
    // the item selected
    setSearchDetail(item)
  }

  const formatResult = (item) => {
    const name = (lang === 'ci') ? item.ci : item.fr
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{name}</span>
      </>
    )
  }

  const handleClick = () => {
    if (lang === 'ci') {
      setLang('fr')
      setIcon('arrow-left')
    } else {
      setLang('ci')
      setIcon('arrow-right')
    }
  }

  return <>
        <Title title='Nouchitionnaire' subtitle="Le dictionnaire de la langue ivoirienne, l'argot ivoirien d'Abidjan !" />
        <div className='hidden md:flex md:flex-col md:justify-center md:items-center'>
            <div className='mt-3 pl-10 pr-10 text-center'>
                <p className='text-transparent bg-clip-text bg-gradient-to-r text-white'><TypeMe typingSpeed="400">Tu as déjà appris pour ma nouvelle promotion. Décidément, il pleut à Paris et Abidjan est mouillé.</TypeMe></p>
            </div>
        </div>
        <Flags icon={icon} actionClick={handleClick} />
          <Form
            items={items}
            handleOnSearch={handleOnSearch}
            handleOnSelect={handleOnSelect}
            formatResult={formatResult}
            fuseOptions={{ keys: [lang] }}
            resultStringKeyName={lang}
            text={text}
          />
          <Result searchDetail={searchDetail} />
  </>
}

export default Search
