import axios from 'axios'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useState, useEffect, React } from 'react'
import Result from '../components/Result'
import Title from '../components/Title'

function Random () {
  const [word, setWord] = useState({})

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL
    const API_KEY = process.env.REACT_APP_API_KEY
    axios.get(`${API_URL}/api/random`, {
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${API_KEY}`
      }

    }).then((res) => {
      setWord(res.data.word)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [])

  return (
    <>
      <Nav />
      <Title title='Mot nouchi aléatoire' />
      <div className='flex flex-col justify-center items-center mb-20 mr-20 ml-14'>
        <button onClick={() => window.location.reload()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10">
              Relancer
        </button>
        <Result searchDetail={word} />
      </div>
      <Footer fixed={"md:fixed md:bottom-0"} />
    </>
  )
}

export default Random
