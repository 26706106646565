import React from 'react'
import fr from '../assets/img/fr-mini.jpg'
import ci from '../assets/img/ci-mini.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowRight, faArrowLeft)

function Flags ({ icon, actionClick }) {
  return (
    <div className='flex justify-center items-center flex-row mt-12'>
        <div className='mr-5'>
            <img alt='ci flag' src={ ci } className='w-12' />
        </div>
        <div className='cursor-pointer' onClick={actionClick}>
            <FontAwesomeIcon icon={ icon } />
        </div>
        <div className='ml-5'>
            <img alt='fr flag' src={ fr } className='w-12 h-7' />
        </div>
    </div>
  )
}

export default Flags
