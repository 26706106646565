import React from 'react'

function Result ({ searchDetail }) {
  if (Object.keys(searchDetail).length === 0) {
    return (<></>)
  }

  return (
    <div className='flex flex-col ml-10 mt-10'>
      <div className='text-3xl mb-5'>
          <div className='flex flex-row space-x-3'>
            <div>
              <h2>{searchDetail.ci}</h2>
            </div>
          </div>
      </div>
      <div className='text-xl mb-5'>
          <span className='font-bold'>Traduction française :</span> {searchDetail.fr}
      </div>
      {searchDetail.example
        ? <div className='text-xl mb-5'>
            <span className='font-bold'>Exemple :</span> {searchDetail.example}
          </div>
        : <></>
      }
      {
        searchDetail.synonymous && searchDetail.synonymous.length > 0
          ? <div>
              <p className='text-xl mb-5'>
                <span className='font-bold'>Synonymes :</span>
              </p>
              <div className='flex flex-row space-x-5'>
                {searchDetail.synonymous.map((synonymous, index) => <div key={index}>{synonymous}</div>)}
              </div>
            </div>
          : <></>
      }
    </div>
  )
}

export default Result
