import React from 'react'
import { NavLink } from 'react-router-dom'
import nctLogo from '../assets/img/nct-logo.png'

function Nav () {
  const active = 'text-xs sm:bg-gray-900 text-white sm:px-3 sm:py-3 rounded-md sm:text-sm sm:font-medium'
  const inactive = 'text-xs text-gray-300 hover:bg-gray-700 hover:text-white sm:px-3 sm:py-3 rounded-md sm:text-sm sm:font-medium'
  return (
    <>
      <nav className="bg-gray-800">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center">
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  <NavLink to="/">
                    <img alt="NCT logo" src={nctLogo} className="hidden sm:block" width={50}/>
                  </NavLink>
                  <NavLink to="/" className={({ isActive }) => (isActive ? active : inactive)}>
                      Nouchitionnaire
                  </NavLink>
                  <NavLink to="/random" className={({ isActive }) => (isActive ? active : inactive)}>
                      Mot aléatoire
                  </NavLink>
                  <NavLink to="/about" className={({ isActive }) => (isActive ? active : inactive)}>
                      A propos
                  </NavLink>
                  <NavLink to="/mentions" className={({ isActive }) => (isActive ? active : inactive)}>
                      Mentions Légales
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Nav
