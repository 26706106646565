import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Random from './pages/Random'
import Mentions from './pages/Mentions'
import Privacy from './pages/Privacy'

function App () {
  return (
    <BrowserRouter forceRefresh>
      <Routes>
        <Route path='/' element={ <Home /> } />
        <Route path='/random' element={ <Random /> } />
        <Route path='/about' element={ <About /> } />
        <Route path='/mentions' element={ <Mentions /> } />
        <Route path='/privacy' element={ <Privacy /> } />
        <Route path='*' element={ <Home /> }/>
      </Routes>
    </BrowserRouter>
  )
}

export default App
