import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Title from '../components/Title'

function About () {
  return (
    <>
      <Nav />
      <Title title='A propos' />
      <div className='flex flex-col mb-20 mr-20 justify-center items-center'>
        <div className='mt-10 ml-14 max-w-4xl'>
            <p>
            Le Nouchitionnaire est un dictionnaire qui recense les mots et expressions de l&apos;argot ivoirien connu sous le nom de &quot;nouchi&quot;. Cette forme de langage créole, principalement parlée à Abidjan, est utilisée par les jeunes urbains et est devenue une partie intégrante de la culture populaire ivoirienne. Le Nouchitionnaire est un outil pratique pour ceux qui souhaitent comprendre le nouchi, en particulier les non-initiés qui peuvent se sentir perdus face à ce langage coloré et dynamique.
            </p>
        </div>
        <div className='mt-6 ml-14 max-w-4xl'>
            <p>
            Le Nouchitionnaire comprend des définitions claires et concises des mots nouchis, ainsi que des exemples d&apos;utilisation dans des phrases courantes.
            </p>
        </div>
        <div className='mt-6 ml-14 max-w-4xl'>
            <p>
            Le Nouchitionnaire est un outil indispensable pour les amateurs de culture ivoirienne, les linguistes, les étudiants et tous ceux qui souhaitent comprendre et apprécier le nouchi. Que vous soyez à la recherche d&apos;un mot nouchi spécifique ou simplement curieux de découvrir cette langue colorée, le Nouchitionnaire est un guide pratique et accessible qui vous aidera à comprendre et à apprécier cette forme unique d&apos;argot ivoirien.
            </p>
        </div>
      </div>
      <Footer fixed={"md:fixed md:bottom-0"} />
    </>
  )
}

export default About
