import React from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function Form ({ items, handleOnSearch, handleOnSelect, formatResult, fuseOptions, resultStringKeyName, text }) {
  return (
    <div className='flex flex-col justify-center items-center mt-10'>
        <div>
        <header
            className="form-control
            block
            w-50
            px-4
            py-2
            text-xl
            font-normal
            text-gray-700
             bg-clip-padding
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:border-blue-600 focus:outline-none w-96">
        <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            autoFocus
            formatResult={formatResult}
            fuseOptions={fuseOptions}
            placeholder="Saisissez un début de mot..."
            inputSearchString={text}
            resultStringKeyName={resultStringKeyName}
          />
          </header>
        </div>
        <div>
        </div>
    </div>
  )
}

export default Form
